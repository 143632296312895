<template>
    <div class="memod-privacy-policy">
        <div class="legal">
            <div class="container">
                <div class="header">
                    <h1>Privacy Policy</h1>
                    <span>WELCOME TO MEMO’D!</span>
                </div>
                <div class="table-of-content">
                    <span>1. <a href="#1">Introduction</a></span>
                    <span>2. <a href="#2">Information Collection Practices</a></span>
                    <span>3. <a href="#3">Use and Sharing of Information</a></span>
                    <span>4. <a href="#4">Storing of Information</a></span>
                    <span>5. <a href="#5">Marketing</a></span>
                    <span>6. <a href="#6">Third Party Sites</a></span>
                    <span>7. <a href="#7">Reserved</a></span>
                    <span>8. <a href="#8">European Economic Area Data Subject Rights</a></span>
                    <span>9. <a href="#9">Contacting Us</a></span>
                    <span>10. <a href="#10">Changes to the Policy</a></span>
                </div>
                <div id="1" class="category">
                    <h5 class="category-title">
                        1. INTRODUCTION
                    </h5>
                    <ol>
                        <li>
                            1.1. <strong>PURPOSE OF POLICY.</strong><br><br>
                            <p>Memo’d Technologies, LLC (“Us,” “We,” “Our,” or “Memo’d”), is committed to respecting the privacy rights of visitors to and users of the website located at the following URLs: <a style="color: #05A88F" href="https://www.memod.com/" target="_blank">https://www.memod.com/</a> and <a style="color: #05A88F" href="https://www.memod.com/insights/" target="_blank">https://www.memod.com/insights/</a> (each, a “Site”), as well as the Memo’d mobile application downloadable from the Apple App Store (the “App” and together with the Site, the “Platforms”).  These collectively reflect the offerings and services we collectively provide (the “Services”). We created this Privacy Policy (this “Policy”) to give you confidence as you visit and use the Services, and to demonstrate our commitment to fair information practices and the protection of privacy.  This Policy is only applicable to the Services, and not to any other websites or applications that you may be able to access from the Services, each of which may have data collection and use practices and policies that differ materially from this Policy.  Additionally, our Terms of Service (available at <a style="color: #05A88F" href="https://memod.com/legal/terms-of-service" target="_blank">https://memod.com/legal/terms-of-service</a> or <a style="color: #05A88F" href="https://www.memod.com/insights/legal/disclaimer" target="_blank">https://www.memod.com/insights/legal/disclaimer</a>), as applicable) are incorporated by reference into this Policy.  Any capitalized terms used but not defined herein has the respective meanings given to them in the Terms of Service.</p>
                        </li>
                        <li>
                            1.2. <strong>NOTICE CONCERNING MINORS.</strong><br><br>
                            <p>PLEASE NOTE: Although We provide general audience Services, We do not direct any of Our content specifically at persons under 18 years of age, or the applicable age of majority in the applicable state, province, territory, or country of residence (“Minors”).  Minors, however, may access the Services with their parent’s or legal guardian’s permission, as described in Section 4.6 of Our Terms of Service.  We understand and are committed to respecting the sensitive nature of the privacy of Minors online.  If We learn or have reason to suspect that a User or Member, under the age of 18, registered for an account without obtaining a parent’s or legal guardian’s permission, then We will promptly delete the account and any personal information in such account.</p>
                        </li>
                    </ol>
                </div>
                <div id="2" class="category">
                    <h5 class="category-title">
                        2. INFORMATION COLLECTION PRACTICES
                    </h5>
                    <ol>
                        <li>
                            2.1. <strong>WHAT BASIC INFORMATION DO WE COLLECT?</strong><br><br>
                            <p>In operating the Services, Memo’d may collect personal identification information such as your name, e-mail address, location, IP address, and other relevant information through your registration with Memo’d to use the Services; online surveys; online forms; and information in communications sent by you to Memo’d through the Platforms or other Services.	You hereby understand and agree that no expectation of privacy exists in this information.  As a routine practice, Memo’d maintains information when you engage in any of the activities described below, including but not limited to the following:</p>
                            <p>(a) When you register an account with Memo’d as a User or Member for the Services, we collect the information you voluntarily provide to create your account profile for the Services.  We may collect additional information for Our records that may not be displayed as part of your account profile, but is necessary to check the information provided or to accept payment from you; and</p>
                            <p>(b) When you purchase a subscription, your payment information is submitted directly to Stripe, the third party credit card payment processor who is responsible for its use and security.  Only a record of the transaction, not the payment information, is stored via the Services.  Your payment will be processed by Stripe, who collects, uses, and processes your information in accordance with their privacy policies.</p>
                            <p>For more information, please refer to Stripe’s <a target="_blank" href="https://stripe.com/gb/privacy">Privacy Policy.</a></p>
                        </li>
                        <li>
                            2.2. <strong>WHAT ADDITIONAL INFORMATION DO WE COLLECT?</strong><br><br>
                            <p>(a) TRAFFIC. Our Services automatically recognize visitors’ domain names, IP addresses (the number assigned to computers on the Internet), geolocations, and application types.  No personal information about that visitor is revealed in this process.  The Services may also gather anonymous traffic data or application usage data that does not personally identify you, but that may be helpful for marketing purposes or for improving the services We offer.</p>
                            <p>(b) BASIC ENGAGEMENT.  Our servers track simple account level engagement data related to content consumption and application usage.  No personal information about you is utilized or revealed, and all information tracked is tied to consumption and usage, meant for aggregate content or service improvement.</p>
                            <p>(c) TRACKING. From time to time, We may use the standard “cookies” feature of major browser applications or other typical mobile equivalents that allows Us to store a small piece of data on your device about your visit to Our Services.  We do not set any personally identifiable information in cookies, nor do We employ any data capture mechanisms on Our Platform other than cookies.  For more information on the types of cookies We use, their functions, and enabling or disabling cookies, please see Our cookies policy (available at [  ]).</p>
                            <p>(d) DEVICE INFORMATION. We may also collect information about the device on which you are using the Services, including what type of device it is, what operating system you are using, device settings, unique device identifiers, and crash data.  Whether We collect some or all of this information often depends on what type of device you are using and its settings.  If you are using the Services on your mobile device, you may also choose to provide Us with location data.</p>
                            <p>(e) ANALYTICS, USAGE, AND ADVERTISING.  We may use third-party analytics tools and work with certain third-party service providers to collect information about the use of Our Services to help Us understand and improve the use of Our Services.  These third-party service providers may collect information sent by your device as part of a web page request, such as cookies or your IP address.  Third-party ad partners may also share information with Us to help Us measure and tailor ads.</p>
                            <ol>
                                <li>
                                    <strong>Google Tag Manager</strong><br><br>
                                    <p>Google Tag Manager (“GTM”) is a tag management system provided by Google Inc. (“Google”) that allows marketed website tags to be managed using an interface.  GTM (which implements the tags) does not process your personal data.</p>
                                    <p>For more information, please refer to Google’s Privacy Policy and <a target="_blank" href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/">terms of use.</a></p>
                                </li>
                                <li>
                                    <strong>Google Analytics</strong><br><br>
                                    <p>Google Analytics is a web analysis tool provided by Google to collect information about the use of Our Services (e.g., Google Analytics collects information such as how often Users or Members visit the Services, what pages they visit when they do so, and what other sites they used prior to coming to the Services).  Google Analytics collects only the IP address assigned to you on the date that you visit the Services, rather than your name or other identifying information.  The information collected through the use of Google Analytics is not combined with your personal information.</p>
                                    <p>For more information about how Google Analytics collects and processes data and opt-out options, please refer to Google’s <a target="_blank" href="https://policies.google.com/technologies/partner-sites">Privacy Policy.</a> You can also prevent Google Analytics from using your data during future visits to Our Platform with the <a target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=en">Google Analytics Opt-out Browser Add-on.</a></p>
                                </li>
                                <li>
                                    <strong>Sentry.io</strong><br><br>
                                    <p>We use services provided by Sentry.io (“Sentry”) for recording, notifying, and tracking errors in Our Apps.</p>
                                    <p>For more information about Sentry, please refer to Sentry’s <a target="_blank" href="https://sentry.io/privacy/">Privacy Policy.</a></p>
                                </li>
                                <li>
                                    <strong>Amplitude</strong><br><br>
                                    <p>We use an analytics service provided by Amplitude, Inc. (“Amplitude”) for recording your interactions with Our Platform, which helps Us deliver a better user experience.</p>
                                    <p>For more information about Amplitude, please refer to the Amplitude’s <a target="_blank" href="https://amplitude.com/privacy">Privacy Notice.</a></p>
                                </li>
                                <li>
                                    <strong>Mailchimp</strong><br><br>
                                    <p>We use Mailchimp to manage Our newsletters and other communications to Users.</p>
                                    <p>For more information about Mailchimp, please refer to Mailchimp’s <a target="_blank" href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>, and more specifically the “<a target="_blank" href="https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts">Privacy for Contacts</a>” section.</p>
                                </li>
                                <li>
                                    <strong>OneSignal</strong><br><br>
                                    <p>We use OneSignal, a third party messaging/communication service application provided by Lilomi, Inc. which allows Us to send messages and notifications to Users Our Platforms. Depending on the permissions granted, the data collected may include precise location (e.g., GPD-level data), WiFi information, device identifier, and usage frequency. </p>
                                    <p>For more information about OngSignal, please refer to OneSignal’s <a target="_blank" href="https://onesignal.com/privacy_policy#1">Privacy Policy.</a></p>
                                </li>
                            </ol>
                            <p>(f) REMARKETING AND BEHAVIORAL TARGETING. These services allow Us and Our partners to inform, optimize, and serve advertising based on your past use of Our Platform and Services. This activity is performed by tracking usage data and by using cookies.</p>
                            <ol>
                                <li>
                                    <strong>Facebook Pixel</strong><br><br>
                                    <p>We use the visitor action pixel from Facebook for conversion measurement to track the behavior of site visitors who have been redirected to Our Platform by clicking on a Facebook ad.</p>
                                    <p>For more information about Facebook, please refer to the Facebook <a target="_blank" href="https://www.facebook.com/about/privacy/">Privacy Policy.</a></p>
                                </li>
                            </ol>
                            <p>(g) SOCIAL NETWORKING SITES. Our Services may include interfaces that allow you to connect with social networking sites (each a “SNS”). If you connect to a SNS through our Services you authorize Us to access, use, and store the information that you agreed the SNS could provide to Us based on your settings on that SNS. We will access, use, and store that information in accordance with this Policy.  You can revoke Our access to the information you provide in this way at any time by amending the appropriate settings from within your account settings on the applicable SNS.</p>
                            <ol>
                                <li>
                                    <strong>Facebook Login (formerly Facebook Connect)</strong><br><br>
                                    <p>Our Platform may contain features from Facebook. Instead of registering directly on Our Platform, you can sign up and login with Facebook Login to facilitate single sign-on.  The provider of this service is Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland. If you decide to register with Facebook Login and click on the “Sign Up with Facebook” button, you will automatically be redirected to the Facebook platform and you can log in with your usage data. This links your Facebook profile to Our Platform or Services.</p>
                                    <p>If you are logged into your Facebook account while visiting Our Platform, a direct connection between your browser and the Facebook server may be established.  If you click on the Facebook button while you are logged into your Facebook account, you can link the contents of Our Platform to your Facebook profile.  This allows Facebook to associate your visit to Our Platform with your Facebook account.  If you do not want Facebook to associate visiting Our Platform with your Facebook account, please log out of your Facebook user account prior to visiting Our Platform.</p>
                                    <p>For more information, please refer to the Facebook <a target="_blank" href="https://www.facebook.com/about/privacy/">Privacy Policy.</a></p>
                                </li>
                                <li>
                                    <strong>Google Login</strong><br><br>
                                    <p>You may have the option to log in to Our Platform using the Google Login sign-in service.  This service authenticates your identity and provides you the option to share certain personal information with Us.  If you are logged into your Google account while visiting Our Platform, a direct connection between your browser and Google’s server may be established.  This allows Google to associate your visit to Our Platform with your Google account.</p>
                                    <p>For more information, please refer to the Google <a target="_blank" href="https://policies.google.com/privacy?hl=en">Privacy Policy.</a></p>
                                </li>
                                <li>
                                    <strong>Sign in with Apple</strong><br><br>
                                    <p>You may have the option to log in to Our Platform using your Apple ID through the Sign in with Apple service.  This service authenticates your identity and provides you the option to share certain personal information with Us.</p>
                                    <p>For more information, please refer to Apple’s <a target="_blank" href="https://www.apple.com/legal/privacy/">Privacy Policy.</a></p>
                                </li>
                            </ol>
                            <p>(g) OTHER SERVICES. You may also give us permission to access your information through other services. The information we receive from those services depends on your settings or their privacy policies.</p>
                        </li>
                    </ol>
                </div>
                <div id="3" class="category">
                    <h5 class="category-title">
                        3. USE AND SHARING OF INFORMATION
                    </h5>
                    <ol>
                        <li>
                            3.1. <strong>WHAT DO WE DO WITH THE COLLECTED INFORMATION?</strong><br><br>
                            <p>(a) PERSONAL INFORMATION. We will not share your personal information (e.g., your name and email address) with any third parties marketers without your consent, except as necessary in conjunction with the provision of communication exchange services of Our Services or to comply with the law. We may use your personal information to verify your identity, or to follow up with transactions initiated through the Services. We may also use your contact information to inform you of any changes to the Services, or to send you additional information about Memo’d.</p>
                            <p>(b) ANONYMOUS INFORMATION. We use anonymous information to analyze our Services, but we do not examine this information for individually identifying information. In addition, we may use anonymous IP addresses to help diagnose problems with Our server, to administer Our Services, or to display the content according to your preferences. Traffic and transaction information may also be shared with business partners and advertisers on an aggregate and anonymous basis.</p>
                            <p>(c) USE OF COOKIES. We may use cookies to deliver content specific to your interests, to save your password so you do not have to re-enter it each time you visit Our Platform, or for other purposes. Promotions or advertisements displayed on Our Platform may contain cookies. We do not have access to or control over information collected by outside advertisers on Our Platforms.</p>
                            <p>(d) DISCLOSURE OF PERSONAL INFORMATION. We may disclose personal information if required to do so by law or in the good-faith belief that such action is necessary to (1) conform to the edicts of the law or comply with legal process served on Memo’d or its affiliates, (2) protect and defend the rights or property of Memo’d or the users of the Services, (3) act under exigent circumstances to protect the safety of the public or users of the Services, or (4) in compliance with the Terms of Service.</p>
                            <p>(e) TRANSFER OF INFORMATION. In order to accommodate changes in Our business, We may sell or buy portions of Memo’d or other companies or assets, including the information collected through Our platforms.  If Memo’d or substantially all of its assets are acquired, User and Customer information will be one of the assets transferred to the acquirer.</p>
                        </li>
                    </ol>
                </div>
                <div id="4" class="category">
                    <h5 class="category-title">
                        4. STORING OF INFORMATION
                    </h5>
                    <ol>
                        <li>
                            4.1. <strong>HOW DO WE STORE YOUR DATA?</strong><br><br>
                            <p>We securely store your data in one or more databases hosted by third parties located in the United States. These third parties do not use or have access to your personal information for any purpose other than cloud storage and retrieval.</p>
                            <ol>
                                <li>
                                    <strong>AWS Data Center</strong><br><br>
                                    <p>We host our backend applications through the Amazon Web Services (“AWS”) Data Center, which stores user data such as emails, addresses, first names, last names, and interaction data.</p>
                                    <p>For more information, please refer to the AWS <a target="_blank" href="https://aws.amazon.com/privacy/">Privacy Policy</a> and the underlying Amazon <a target="_blank" href="https://www.amazon.de/gp/help/customer/display.html?nodeId=3312401">Privacy Policy.</a></p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            4.2. <strong>HOW LONG DO WE KEEP YOUR INFORMATION?</strong><br><br>
                            <p>We will retain your information as long as necessary for the purposes outlined in this Policy, and for a commercially reasonable time thereafter for backup, archival, fraud prevention or detection, or audit purposes, or as otherwise required by law.  We may use remote backup features that will send information from your device and/or browser to be stored on servers operated by or on behalf of Us.  Except to the extent specifically prohibited by applicable law, We have no responsibility for any data transmitted to and from your device and/or computer and We recommend that you make regular back-ups of all information and data on your device and/or computer.</p>
                        </li>
                        <li>
                            4.3. <strong>SECURITY.</strong><br><br>
                            <p>The Services have security measures in place to prevent the loss, misuse, and alteration of the information that We obtain from you, but We make no assurances nor warranties about Our ability to prevent any such loss, misuse, to you or to any third party arising out of any such loss, misuse, or alteration.</p>
                        </li>
                    </ol>
                </div>
                <div id="5" class="category">
                    <h5 class="category-title">
                        5. MARKETING
                    </h5>
                    <ol>
                        <li>
                            <p>We would like to send you information about Our products and services that We think you might like.  If you have agreed to receive marketing, you may always opt out at a later date.  You may opt out of marketing-related emails by clicking on a link at the bottom of each such email, or by contacting us at support@memod.com.  You may continue to receive service-related and other non-marketing emails.</p>
                        </li>
                    </ol>
                </div>
                <div id="6" class="category">
                    <h5 class="category-title">
                        6. THIRD PARTY SITES
                    </h5>
                    <ol>
                        <li>
                            <p>The Site, App, and other Memo’d-related platforms may contain links to other websites. If you choose to visit other websites, We are not responsible for the privacy practices or content of those other websites, and it is your responsibility to review the privacy policies related to those websites to confirm that you understand and agree with their policies.</p>
                        </li>
                    </ol>
                </div>
                <div id="7" class="category">
                    <h5 class="category-title">
                        [7. RESERVED]
                    </h5>
                </div>
                <div id="8" class="category">
                    <h5 class="category-title">
                        8. EUROPEAN ECONOMIC AREA DATA SUBJECT RIGHTS
                    </h5>
                    <ol>
                        <li>
                            <p>If you are a natural person residing within the European Economic Area, the following additional data protection rights are applicable to you:</p>
                            <ul>
                                <li>RIGHT TO ACCESS.  You have the right to request from Us copies of your personal data.  We may charge you a fee for this service.</li>
                                <li>Right to Rectification.  You have the right to request that We correct or modify any information you provided to Us or associated with your account which you believe is inaccurate.  You also have the right to request that We complete information you believe is incomplete. </li>
                                <li>Right to Erasure.  You have the right to request that We erase your personal data, under certain conditions. Please note that the Service, or parts of the Service, may become inaccessible or otherwise not function properly if you request to have your personal data erased or not be processed.</li>
                                <li>Right to Restrict Processing.  You have the right to request that We restrict the processing of your personal data, under certain conditions. Please note that the Service, or parts of the Service, may become inaccessible or otherwise not function properly if you withdraw certain consents or otherwise restrict the processing of your personal data.</li>
                                <li>Right to Object to Processing.  You have the right to object to Our processing of your personal data, under certain conditions. Please note that the Service, or parts of the Service, may become inaccessible or otherwise not function properly if you withdraw certain consents or otherwise object to the processing of your personal data.</li>
                                <li>Right to Data Portability.  You have the right to request that We transfer the data that We have collected to another organization, or directly to you, under certain conditions. </li>
                                <li>International Transfers.  Please be aware that the information We collect may be transferred to and maintained on servers or databases located outside your jurisdiction, where the privacy laws may not be as protective as those in your location. </li>
                            </ul>
                        </li>
                    </ol>
                </div>
                <div id="9" class="category">
                    <h5 class="category-title">
                        9. CONTACTING US
                    </h5>
                    <ol>
                        <li>
                            <p>For user account related inquiries, please contact Our customer support team by email at <a href="mailto:support@memod.com">support@memod.com.</a></p>
                            <p>If you have any questions about this Policy, Our practices related to the Services, or if you would like to have Us remove your information from Our database, then please feel to contact Us by sending an email to <a href="mailto:legal@memod.com.">legal@memod.com.</a></p>
                            <p>If you live within the European Economic Area, the data controller of your personal information is Memo’d Technologies, LLC and can be contacted by email at <a href="mailto:legal@memod.com.">legal@memod.com.</a></p>
                            <p>Please note that if you contact Us to assist you, for your safety and Ours we may need to authenticate your identity before fulfilling your request.</p>
                        </li>
                    </ol>
                </div>
                <div id="10" class="category">
                    <h5 class="category-title">
                        10. CHANGES TO THE POLICY
                    </h5>
                    <ol>
                        <li>
                            <p>We reserve the right, at any time, to add to, change, update, supplement, or modify this Policy, simply by posting such change, update, or modification on the Site, App, and other Memo’d platforms, and without any other notice to you.  Any such change, update, or modification will be effective immediately upon posting. Please review this Privacy Policy periodically, and especially before you provide any information, to ensure that you continue to agree with all of its terms. If you continue to use the Service after those changes, updates, or modifications are in effect, you agree to the revised Policy.</p>
                            <p>Last updated: October 2020</p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MemodPrivacyPolicy",
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
